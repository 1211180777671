import React, { useState, useEffect } from "react";
import { PageTitle } from "./pageTitle";
import JsonData from "../data/data.json";
import "../App.css";


const SecurityDados = () => {

    return (
        <div>
            <PageTitle title="Política de Proteção de Dados Pessoais" />
            <div id="bodyContrato" className="text-just">
                <p>Esta Política de Uso de Dados Pessoais (“Política”) tem por objetivo estabelecer as condições referentes à coleta, uso e divulgação de dados pessoais pela FazBank Pagamentos LTDA. (“FazBank”), conforme a legislação aplicável.</p>

                <p>Ao utilizar quaisquer produtos ou serviços da FazBank ou iniciar quaisquer tratativas, negociações ou relacionamento de qualquer natureza com a FazBank, você declara ter lido e concordar com o conteúdo desta Política, autorizando a FazBank a usar as informações pessoais a que tenha acesso conforme os termos e condições aqui estabelecidos.</p>

                <h5>Finalidade da coleta e uso dos dados</h5>
                <p>Em determinadas situações, a FazBank realiza a coleta, tratamento e armazenamento de determinados dados pessoais para poder prestar adequadamente seus serviços e aprimorar sua comunicação com pessoas com quem mantém relacionamentos que impliquem o tratamento. Tais dados podem ser recebidos diretamente da pessoa física titular ou indiretamente, por intermédio de um terceiro com o qual a pessoa física mantenha relacionamento.</p>

                <p>Os dados pessoais coletados, inclusive dados biométricos, como fotografias ou biometria facial, podem ser utilizados pela FazBank para (i) aprimorar a prestação de serviços, os produtos e serviços oferecidos; (ii) testar, desenvolver e aprimorar os sistemas da FazBank; (iii) gerenciar o relacionamento e as comunicações com colaboradores, candidatos, clientes e parceiros; (iv) responder a demandas e reclamações; (v) realizar análises e pesquisas e criar dashboards e bancos de dados, os quais poderão ser disponibilizados a terceiros, desde que de forma anonimizada; (vi) como garantia de prevenção à fraude e da segurança do titular, nos processos de identificação e autenticação de cadastro e acesso; e quando necessário, poderá ser compartilhado e armazenado pelos nossos prestadores de serviço, que realizarão o tratamento do dado de acordo com a LGPD; e/ou (vii) divulgar produtos e serviços da FazBank e seus parceiros em ações de marketing.</p>

                <h5>Manutenção dos dados</h5>
                <p>A FazBank retém apenas os dados essenciais para as finalidades aqui previstas e somente pelo tempo necessário para os propósitos aqui declarados.</p>

                <h5>Divulgação de dados</h5>
                <p>Os dados pessoais serão divulgados apenas em consonância com os termos desta política e mediante implementação de medidas técnicas e organizacionais adequadas de proteção, conforme previsto na legislação aplicável.</p>

                <p>A FazBank poderá divulgar e armazenar dados pessoais para cumprimento de obrigações legais e regulatórias (como auditorias, fiscalizações ou investigações de autoridades competentes), para execução de suas políticas corporativas, para o exercício regular de direitos em processos judiciais, administrativos ou arbitrais, na concessão e proteção ao crédito, para prevenção à fraude e garantia da segurança do titular em processos de identificação e acesso, para atender a demandas de terceiros ou para a proteção da vida ou da incolumidade física do titular ou de terceiro, sempre conforme as previsões legais aplicáveis.</p>

                <p>A FazBank poderá compartilhar os dados pessoais identificáveis do titular com empresas pertencentes ao mesmo grupo econômico ou parceiros de negócios, inclusive através de sistemas externos, desde que tais empresas, parceiros de negócios e provedores de sistemas mantenham políticas de proteção de dados equivalentes às estabelecidas neste documento e sempre em observância aos interesses do titular. Este compartilhamento fica desde já autorizado e é aqui expressamente consentido. Ao utilizar quaisquer produtos ou serviços, ou iniciar quaisquer tratativas, negociações ou relacionamento de qualquer natureza com a FazBank, você declara ter lido e concordar com esta cláusula, sem prejuízo dos demais dispositivos contidos na Política.</p>

                <p>Caso a FazBank seja objeto de reorganização societária ou aquisição por terceiros, a FazBank poderá compartilhar dados pessoais com os terceiros envolvidos e seus respectivos consultores. A adquirente ou a nova entidade resultante da reorganização deverão observar os termos desta política.</p>

                <p>Ressalvadas hipóteses legais e as aqui mencionadas, a FazBank não divulgará dados pessoais a terceiros sem o consentimento de seu titular.</p>

                <h5>Proteção dos dados pessoais</h5>
                <p>Todos os dados coletados pela FazBank são armazenados de forma segura, em sistema de tecnologia no estado da arte, com monitoramento constante e acesso apenas por pessoas autorizadas, em estrita conformidade com a legislação aplicável.</p>

                <p>A FazBank adota continuamente as medidas necessárias e razoáveis, conforme o padrão da indústria, para garantir a privacidade, integridade e segurança dos dados do titular, possuindo plano de ação adequado para os casos de vazamento de informações. No entanto, mesmo com a adoção das devidas medidas de segurança, a FazBank não assegura que atos ilícitos não sejam cometidos por terceiros, não podendo, portanto, ser responsabilizada por eventual destruição, alteração, vazamento ou uso inadequado de informações ilicitamente obtidas por terceiros.</p>

                <h5>Direitos do titular dos dados</h5>
                <p>O titular das informações pessoais poderá solicitar, a qualquer tempo, acesso aos dados pessoais e informações relativas a eventual compartilhamento dos dados, por meio dos canais de atendimento da FazBank, especialmente o e-mail <a href="mailto:sac@MettaBank.com.br">sac@MettaBank.com.br</a>, através do qual pode ser contactado o encarregado de dados pessoais. Caso seja verificado qualquer erro, inconsistência ou discrepância nos dados armazenados pela FazBank, o titular poderá demandar sua pronta correção ou atualização.</p>

                <p>O titular também poderá, a qualquer tempo, se opor ao tratamento de seus dados pessoais pela FazBank, restringir seu uso, revogar o consentimento dado para tratamento de determinados dados pessoais, ou exigir a exclusão de seus dados pessoais dos bancos de dados da FazBank, conforme estabelecido na legislação aplicável. A solicitação deverá ser feita por meio dos canais de atendimento da FazBank e será atendida no prazo de até 15 (quinze) dias, contado da data do requerimento do titular. No entanto, é importante destacar que, nesses casos, a FazBank poderá ficar impossibilitada de manter relacionamentos que impliquem tratamento de dados pessoais e de fornecer seus serviços e produtos, podendo eventuais tratativas e serviços contratados serem considerados cancelados unilateralmente pelo titular, sujeito às penalidades estabelecidas em contrato.</p>

                <h5>Alterações da política</h5>
                <p>Esta Política poderá ser alterada ou atualizada periodicamente pela FazBank em razão de mudança na legislação, ou regulamentação aplicável, ou para adequação aos produtos e serviços oferecidos pela FazBank.</p>

                <p>Eventuais alterações à presente Política entrarão em vigor imediatamente e estarão acessíveis por meio da plataforma FazBank e demais canais disponíveis.</p>

            </div>
        </div>
    );
};

export default SecurityDados;