import React, { useState, useEffect } from "react";

import { Gallery } from "./gallery";
import { PageTitle } from "./pageTitle";
import { GridElements } from "./gridelements";
import { Team } from "./Team";

import JsonData from "../data/data.json";
import "../App.css";


const Foru = () => {
    const [landingPageData, setLandingPageData] = useState({});
    useEffect(() => {
        setLandingPageData(JsonData);
    }, []);


    return (
        <>
        <PageTitle title="Feito pra acompanhar a sua vida" sub2="Com a FazBank você paga, recebe, transfere em um só aplicativo. Com a facilidade da Conta Digital, que ajuda você a organizar seus gastos e que ajuda você a poupar e a fazer seu dinheiro render." />
        {/* <GridElements data={landingPageData.Services} /> */}
        <GridElements data={landingPageData.Foru2} />
        <GridElements data={landingPageData.Line3} />
        <Team data={landingPageData.Team} />
        <Gallery data={landingPageData.Gallery} /></>
    )
};

export default Foru