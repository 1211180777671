import React from "react";
import { PageTitle } from "./pageTitle";
import "../App.css";


const Garantias = () => {

    return (
        <div>
            <PageTitle title="Garantias" />
            <div id="bodyContrato" className="text-just">                                                                                                                                                                                                                                                                                                                                                          
                <p><strong>1. Carta Garantia para Tutelas de Urgência</strong></p>

                <p>O que é?
                    A Carta Garantia para Tutelas de Urgência é uma garantia financeira usada em situações judiciais que exigem uma resposta rápida, como cobranças indevidas ou bloqueios judiciais de contas bancárias.</p>

                <p>Exemplo e Simulação de Situação:</p>

                <p>Cobrança Indevida: Uma empresa de tecnologia enfrenta uma cobrança indevida de R$500.000 que ameaça suas operações.</p>

                <p>Valor Garantido: R$650.000 (R$500.000 + 30%).
                    Taxa ao Ano: Entre 1,5% e 5%.
                        Custo Aproximado: Entre R$9.750 e R$32.500.
                            Benefício: A empresa pode suspender a cobrança sem comprometer o capital de giro.
                                Base Legal: Lei 13.105/2015 (Código de Processo Civil - CPC).
                                    Bloqueio Judicial de Conta Bancária: Uma empresa de comércio teve suas contas bancárias bloqueadas por uma ordem judicial de R$200.000.</p>

                <p>Valor Garantido: R$260.000.
                    Taxa ao Ano: Entre 1,5% e 5%.
                        Custo Aproximado: Entre R$3.900 e R$13.000.
                            Benefício: A empresa pode liberar suas contas e continuar operando normalmente.
                                Base Legal: Lei 13.105/2015 (CPC).</p>

                <p>&nbsp;</p>

                <p><strong>2. Carta Garantia para Impugnações ao Cumprimento de Sentenças</strong></p>

                <p>O que é?
                    A Carta Garantia para Impugnações ao Cumprimento de Sentenças facilita a contestação de decisões judiciais sem a necessidade de imobilizar grandes quantias de dinheiro.</p>

                <p>Exemplo e Simulação de Situação:</p>

                <p>Sentença Judicial: Uma empresa automotiva precisa impugnar uma sentença judicial que a condenou a pagar R$2.000.000.</p>

            <p>Valor Garantido: R$2.600.000 (R$2.000.000 + 30%).
            Taxa ao Ano: Entre 1,5% e 5%.
            Custo Aproximado: Entre R$39.000 e R$130.000.
            Benefício: A empresa pode contestar a sentença sem desembolso imediato do valor total.
            Base Legal: Lei 13.105/2015 (CPC).
            Disputa Comercial: Uma empresa de manufatura enfrenta uma sentença de R$1.000.000 em uma disputa comercial.</p>

            <p>Valor Garantido: R$1.300.000.
            Taxa ao Ano: Entre 1,5% e 5%.
            Custo Aproximado: Entre R$19.500 e R$65.000.
            Benefício: A empresa pode contestar a sentença sem imobilizar capital significativo.
            Base Legal: Lei 13.105/2015 (CPC).</p>

            <p>&nbsp;</p>

            <p><strong>3. Carta Garantia para Execuções Fiscais</strong></p>

            <p>O que é?
            A Carta Garantia para Execuções Fiscais garante o juízo em embargos à execução fiscal, evitando a penhora de bens.</p>

            <p>Exemplo e Simulação de Situação:</p>

            <p>Passivo Fiscal: Uma empresa de construção civil enfrenta uma execução fiscal de R$3.000.000 devido a impostos atrasados.</p>

            <p>Valor Garantido: R$3.900.000 (R$3.000.000 + 30%).
            Taxa ao Ano: Entre 1,5% e 5%.
            Custo Aproximado: Entre R$58.500 e R$195.000.
            Benefício: A empresa pode evitar a penhora de seus bens e contestar a execução fiscal.
            Base Legal: Lei 13.043/2014 (Lei de Execuções Fiscais).
            Multa Fiscal: Uma empresa de logística tem uma execução fiscal de R$1.500.000.</p>

            <p>Valor Garantido: R$1.950.000.
            Taxa ao Ano: Entre 1,5% e 5%.
            Custo Aproximado: Entre R$29.250 e R$97.500.
            Benefício: A empresa pode evitar a penhora de bens e contestar a multa fiscal.
            Base Legal: Lei 13.043/2014 (Lei de Execuções Fiscais).</p>

            <p>&nbsp;</p>

            <p><strong>4. Carta Garantia para Execuções Trabalhistas</strong></p>

            <p>O que é?
            A Carta Garantia para Execuções Trabalhistas garante o juízo em embargos à execução trabalhista, permitindo que a empresa conteste a execução sem imobilizar grandes quantias de dinheiro.</p>

            <p>Exemplo e Simulação de Situação:</p>

            <p>Disputa Trabalhista: Uma empresa enfrenta uma execução trabalhista de R$1.000.000.</p>

            <p>Valor Garantido: R$1.300.000 (R$1.000.000 + 30%).
            Taxa ao Ano: Entre 1,5% e 5%.
            Custo Aproximado: Entre R$19.500 e R$65.000.
            Benefício: A empresa pode contestar a execução sem comprometer seu capital de giro.
            Base Legal: Lei 13.467/2017 (Reforma Trabalhista).
                Acordo Trabalhista: Uma indústria precisa garantir uma execução trabalhista de R$2.000.000.</p>

            <p>Valor Garantido: R$2.600.000.
                Taxa ao Ano: Entre 1,5% e 5%.
                    Custo Aproximado: Entre R$39.000 e R$130.000.
                        Benefício: A empresa pode contestar a execução sem imobilizar recursos financeiros significativos.
                            Base Legal: Lei 13.467/2017 (Reforma Trabalhista).</p>

            <p>&nbsp;</p>

            <p><strong>5. Carta Garantia para Anulatórias Fiscais</strong></p>

            <p>O que é?
                A Carta Garantia para Anulatórias Fiscais permite às empresas contestarem multas e cobranças fiscais sem a necessidade de desembolsos imediatos significativos.</p>

            <p>Exemplo e Simulação de Situação:</p>

            <p>Multa Fiscal Contestada: Uma empresa varejista recebe uma multa fiscal de R$1.500.000 e deseja contestar a cobrança.</p>

            <p>Valor Garantido: R$1.950.000 (R$1.500.000 + 30%).
                Taxa ao Ano: Entre 1,5% e 5%.
                    Custo Aproximado: Entre R$29.250 e R$97.500.
                        Benefício: A empresa pode contestar a multa fiscal sem comprometer seu capital de giro.
                            Base Legal: PGFN 1153/2009 (Procuradoria-Geral da Fazenda Nacional).
                                Imposto Indevido: Uma empresa de serviços é cobrada indevidamente em R$2.000.000 em impostos.</p>

                            <p>Valor Garantido: R$2.600.000.
                                Taxa ao Ano: Entre 1,5% e 5%.
                                    Custo Aproximado: Entre R$39.000 e R$130.000.
                                        Benefício: A empresa pode contestar a cobrança de impostos sem comprometer seus recursos financeiros.
                                            Base Legal: PGFN 1153/2009 (Procuradoria-Geral da Fazenda Nacional).</p>

                                        <p>&nbsp;</p>

                                                                                                                                                            <p><strong>6. Carta Garantia para Multas Administrativas</strong></p>

                                                                                                                                                            <p>O que é?
                                                                                                                                                                A Carta Garantia para Multas Administrativas garante o pagamento de penalidades impostas por órgãos reguladores sem comprometer o capital de giro da empresa.</p>

                                                                                                                                                            <p>Exemplo e Simulação de Situação:</p>

                                                                                                                                                            <p>Multa Ambiental: Uma indústria química recebe uma multa administrativa de R$500.000 por não conformidade com regulamentos ambientais.</p>

                                                                                                                                                            <p>Valor Garantido: R$650.000 (R$500.000 + 30%).
                                                                                                                                                                Taxa ao Ano: Entre 1,5% e 5%.
                                                                                                                                                                    Custo Aproximado: Entre R$9.750 e R$32.500.
                                                                                                                                                                        Benefício: A empresa pode pagar a multa sem comprometer seu capital de giro.
                                                                                                                                                                            Base Legal: Lei 10.406/2002 (Código Civil).
                                                                                                                                                                                Multa Sanitária: Uma empresa de alimentos é multada em R$1.000.000 por questões sanitárias.</p>

                                                                                                                                                                            <p>Valor Garantido: R$1.300.000.
                                                                                                                                                                                Taxa ao Ano: Entre 1,5% e 5%.
                                                                                                                                                                                    Custo Aproximado: Entre R$19.500 e R$65.000.
                                                                                                                                                                                        Benefício: A empresa pode pagar a multa sem comprometer seus recursos financeiros.
                                                                                                                                                                                            Base Legal: Lei 10.406/2002 (Código Civil).</p>

                                                                                                                                                                                        <p>&nbsp;</p>

                                                                                                                                                                                        <p><strong>7. Carta Garantia para Recursos Trabalhistas</strong></p>

                                                                                                                                                                                        <p>O que é?
                                                                                                                                                                                            A Carta Garantia para Recursos Trabalhistas permite às empresas garantirem recursos em processos trabalhistas sem imobilizar grandes quantias de dinheiro.</p>

                                                                                                                                                                                        <p>Exemplo e Simulação de Situação:</p>

                                                                                                                                                                                        <p>Recurso de Sentença Trabalhista: Uma empresa de serviços recorre de uma sentença trabalhista de R$800.000.</p>

                                                                                                                                                                                        <p>Valor Garantido: R$1.040.000 (R$800.000 + 30%).
                                                                                                                                                                                            Taxa ao Ano: Entre 1,5% e 5%.
                                                                                                                                                                                                Custo Aproximado: Entre R$15.600 e R$52.000.
                                                                                                                                                                                                    Benefício: A empresa pode recorrer da sentença sem comprometer seu capital de giro.
                                                                                                                                                                                                        Base Legal: Lei 13.467/2017 (Reforma Trabalhista).
                                                                                                                                                                                                            Contestação de Ação Trabalhista: Uma empresa de manufatura enfrenta uma ação trabalhista de R$1.200.000.</p>

                                                                                                                                                                                                        <p>Valor Garantido: R$1.560.000.
                                                                                                                                                                                                            Taxa ao Ano: Entre 1,5% e 5%.
                                                                                                                                                                                                                Custo Aproximado: Entre R$23.400 e R$78.000.
                                                                                                                                                                                                                    Benefício: A empresa pode contestar a ação sem comprometer seus recursos financeiros.
                                                                                                                                                                                                                        Base Legal: Lei 13.467/2017 (Reforma Trabalhista).</p>

                                                                                                                                                                                                                    <p>&nbsp;</p>

                                                                                                                                                                                                                    <p><strong>8. Carta Garantia para Contratos Aduaneiros</strong></p>

                                                                                                                                                                                                                    <p>O que é?
                                                                                                                                                                                                                        A Carta Garantia para Contratos Aduaneiros garante operações de importação e exportação sem a necessidade de imobilizar grandes quantias de dinheiro.</p>

                                                                <p>Exemplo e Simulação de Situação:</p>

                                                                <p>Tributos Aduaneiros: Uma importadora precisa garantir o pagamento de tributos aduaneiros de R$2.000.000 para liberar mercadorias no porto.</p>

                                                                <p>Valor Garantido: R$2.600.000 (R$2.000.000 + 30%).
                                                                    Taxa ao Ano: Entre 1,5% e 5%.
                                                                        Custo Aproximado: Entre R$39.000 e R$130.000.
                                                                            Benefício: A empresa pode agilizar a liberação das mercadorias sem comprometer seu capital de giro.
                                                                                Base Legal: Portarias da Receita Federal.
                                                                                    Garantia Aduaneira: Uma exportadora precisa garantir R$1.500.000 em tributos para concluir uma operação de exportação.</p>

                                                                                <p>Valor Garantido: R$1.950.000.
                                                                                    Taxa ao Ano: Entre 1,5% e 5%.
                                                                                        Custo Aproximado: Entre R$29.250 e R$97.500.
                                                                                            Benefício: A empresa pode concluir a operação sem atrasos e sem comprometer seus recursos financeiros.
                                                                                                Base Legal: Portarias da Receita Federal.</p>

                                                                                            <p>&nbsp;</p>

                                                                                            <p><strong>9. Carta Garantia para Projetos de Infraestrutura</strong></p>

                                                                                            <p>O que é?
                                                                                                A Carta Garantia para Projetos de Infraestrutura garante contratos de construção de grande porte sem a necessidade de imobilizar grandes quantias de dinheiro.</p>

                                                                                            <p>Exemplo e Simulação de Situação:</p>

                                                                                            <p>Construção de Rodovia: Uma empresa de construção ganha um contrato de infraestrutura no valor de R$20.000.000 para construir uma rodovia.</p>

                                                                                            <p>Valor Garantido: R$26.000.000 (R$20.000.000 + 30%).
                                                                                                Taxa ao Ano: Entre 1,5% e 5%.
                                                                                                    Custo Aproximado: Entre R$390.000 e R$1.300.000.
                                                                                                        Benefício: A empresa pode iniciar o projeto sem imobilizar grandes quantias de dinheiro.
                                                                                                            Base Legal: Lei 13.105/2015 (Código de Processo Civil - CPC).
                                                                                                                Projeto de Energia: Uma empresa de energia precisa garantir um contrato de R$15.000.000 para a construção de uma usina solar.</p>

                                                                                                            <p>Valor Garantido: R$19.500.000.
                                                                                                                Taxa ao Ano: Entre 1,5% e 5%.
                                                                                                                    Custo Aproximado: Entre R$292.500 e R$975.000.
                                                                                                                        Benefício: A empresa pode iniciar o projeto sem comprometer seu capital de giro.
                                                                                                                            Base Legal: Lei 13.105/2015 (CPC).</p>

                                                                                                                        <p>&nbsp;</p>

                                                                                                                        <p><strong>10. Carta Garantia para Tomada de Crédito</strong></p>

                                                                                                                        <p>O que é?
                                                                                                                            A Carta Garantia para Tomada de Crédito garante operações de crédito com instituições financeiras sem a necessidade de imobilizar grandes quantias de dinheiro.</p>

                                                                                                                        <p>Exemplo e Simulação de Situação:</p>

                                                                                                                        <p>Expansão de Negócios: Uma empresa precisa de crédito de R$5.000.000 para expandir suas operações.</p>

                                                                                                                        <p>Valor Garantido: R$6.500.000 (R$5.000.000 + 30%).
                                                                                                                            Taxa ao Ano: Entre 1,5% e 5%.
                                                                                                                                Custo Aproximado: Entre R$97.500 e R$325.000.
                                                                                                                                    Benefício: A empresa pode obter o crédito necessário com melhores condições sem imobilizar bens.
                                                                                                                                        Base Legal: Lei 10.406/2002 (Código Civil).
                                                                                                                                            Investimento em Equipamentos: Uma fábrica precisa de R$3.000.000 para investir em novos equipamentos.</p>

                                                                                                                                        <p>Valor Garantido: R$3.900.000.
                                                                                                                                            Taxa ao Ano: Entre 1,5% e 5%.
                                                                                                                                                Custo Aproximado: Entre R$58.500 e R$195.000.
                                                                                                                                                    Benefício: A empresa pode financiar a compra dos equipamentos sem comprometer seu capital de giro.
                                                                                                                                                        Base Legal: Lei 10.406/2002 (Código Civil).</p>

                                                                                                                                                    <p>&nbsp;</p>

                                                                                                                                                    <p><strong>11. Carta Garantia para Licitações</strong></p>

                                                                                                                                                    <p>O que é?
                                                                                                                                                        A Carta Garantia para Licitações garante a execução de contratos públicos e licitações sem a necessidade de imobilizar grandes quantias de dinheiro.</p>

                                                                                                                                                    <p>Exemplo e Simulação de Situação:</p>

                                                                                                                                                    <p>Licitação de Obras Públicas: Uma construtora vence uma licitação de R$10.000.000 para a construção de uma escola pública.</p>

                                                                                                                                                    <p>Valor Garantido: R$13.000.000 (R$10.000.000 + 30%).
                                                                                                                                                        Taxa ao Ano: Entre 0,8% e 5%.(Custo em licitação é mais baixo)
                                                                                                                                                            Custo Aproximado: Entre R$104.000 e R$650.000.
                                                                                                                                                                Benefício: A empresa pode iniciar a obra sem imobilizar grandes quantias de dinheiro.
                                                                                                                                                                    Base Legal: Lei 8.666/1993 (Lei de Licitações).
                                                                                                                                                                        Projeto de Infraestrutura Urbana: Uma empresa de engenharia ganha uma licitação de R$8.000.000 para a revitalização de uma área urbana.</p>

                                                                                                                                                                    <p>Valor Garantido: R$10.400.000.
                                                                                                                                                                        Taxa ao Ano: Entre 1,5% e 5%.
                                                                                                                                                                            Custo Aproximado: Entre R$156.000 e R$520.000.
                                                                                                                                                                                Benefício: A empresa pode iniciar o projeto sem comprometer seu capital de giro.
                                                                                                                                                                                    Base Legal: Lei 8.666/1993 (Lei de Licitações).</p>

                                                                                                                                                                                <p>&nbsp;</p>

                                                                                                                                                                                <p><strong>12. Substituição de Garantias</strong></p>

                                                                                                                                                                                <p>O que é?
                                                                                                                                                                                    A Substituição de Garantias permite às empresas liberarem seus bens alienados, substituindo-os por uma Carta Garantia emitida por instituições financeiras autorizadas.</p>

                                                                                                                                                                                <p>Exemplo e Simulação de Situação:</p>

                                                                                                                                                                                <p>Imóvel Alienado: Uma empresa possui um imóvel avaliado em R$5.000.000 que está alienado como garantia em um contrato vigente.</p>

                                                                                                                                                                                <p>Valor Garantido: R$6.500.000 (R$5.000.000 + 30%).
                                                                                                                                                                                    Taxa ao Ano: Entre 1,5% e 5%.
                                                                                                                                                                                        Custo Aproximado: Entre R$97.500 e R$325.000.
                                                                                                                                                                                            Benefício: A empresa libera o imóvel, que pode ser vendido ou utilizado para obter novos financiamentos, transformando-o em liquidez sem comprometer o patrimônio.
                                                                                                                                                                                                Base Legal: Lei 11.382/2006 (Código de Processo Civil - CPC).
                                                                                                                                                                                                    Veículos Alienados: Uma transportadora tem uma frota de veículos avaliada em R$2.000.000 alienada como garantia para um empréstimo.</p>

                                                                                                                                                                                                <p>Valor Garantido: R$2.600.000.
                                                                                                                                                                                                    Taxa ao Ano: Entre 1,5% e 5%.
                                                                                                                                                                                                        Custo Aproximado: Entre R$39.000 e R$130.000.
                                                                                                                                                                                                            Benefício: A empresa pode vender ou refinanciar os veículos, obtendo liquidez para investimentos ou capital de giro.
                                                                                                                                                                                                                Base Legal: Lei 11.382/2006 (CPC).
                                                                                                                                                                                                                    Recebíveis Alienados: Uma empresa de serviços tem recebíveis futuros no valor de R$3.000.000 alienados como garantia.</p>

                                                                                                                                                                                                                <p>Valor Garantido: R$3.900.000.
                                                                                                                                                                                                                    Taxa ao Ano: Entre 1,5% e 5%.
                                                                                                                                                                                                                        Custo Aproximado: Entre R$58.500 e R$195.000.
                                                                                                                                                                                                                            Benefício: A empresa pode utilizar os recebíveis para novos financiamentos ou como capital de giro.
                                                                                                                                                                                                                                Base Legal: Lei 11.382/2006 (CPC).
                                                                                                                                                                                                                                    A Substituição de Garantias da Emih oferece uma solução eficaz para as empresas que precisam liberar seus bens alienados e transformá-los em liquidez. Com taxas de juros competitivas e a flexibilidade de uso, esta modalidade permite que os clientes substituam as garantias de bens imóveis, veículos, ou outros ativos por uma Carta Garantia, viabilizando o uso dos recursos financeiros de maneira mais estratégica.</p>
                                                                                                                                                                                                                                                                                                        </div>
   </div>
    );
};

export default Garantias;