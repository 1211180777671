import React, { useState, useEffect } from "react";

import { Gallery } from "./gallery";
import { PageTitle } from "./pageTitle";
import { GridElements } from "./gridelements";
import { Team } from "./Team";

import JsonData from "../data/data.json";
import "../App.css";


const ForuJob = () => {
    const [landingPageData, setLandingPageData] = useState({});
    useEffect(() => {
        setLandingPageData(JsonData);
    }, []);


    return (
        <>
        <PageTitle title="Conta Digital PF e PJ completa, segura e sem burocracias." sub2="Na FazBank sua conta é 100% online. A agilidade e simplicidade que seus negócios precisam. Abra sua conta digital, é segura, fácil e rápido!" />
        {/* <GridElements data={landingPageData.Services}/> */}
        <GridElements data={landingPageData.Foru2} />
        <GridElements data={landingPageData.Line3} />
        <Team data={landingPageData.Team} />
        <Gallery data={landingPageData.Gallery} /></>
    )
};

export default ForuJob;